import React, { useReducer } from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { useStaticQuery, graphql } from 'gatsby'

import ButtonWhiteArrow from 'components/elements/Buttons/ButtonWhiteArrow'
import Refresh from 'components/elements/SvgElements/Refresh'
import Plaatjie from '@ubo/plaatjie'
import ViewPortAnimatedFingerprint from 'components/elements/FingerPrint'

const StyledTeam = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 50;
  max-width: 1600px;
  margin: 0 auto;
`

const Container = styled.div`
  position: relative;
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  border-radius: 41px;

  padding: 0.75rem 0.5rem 3rem 0.5rem;
  @media (min-width: 992px) {
    margin: 0 5rem;
    padding: 2rem 3rem;
  }
`

const Content = styled(ParseContent)`
  @media (max-width: 991.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 24px;
      text-align: center;
      padding-top: 1rem;
    }
  }

  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 50px;
      line-height: 48px;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-weight: 400 !important;
    color: #ffffff;
  }
`

const SVGWrapper = styled.div`
  position: absolute;
  right: 14px;
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991.98px) {
    width: 28px;
    height: 28px;
  }

  @media (min-width: 992px) {
    width: 37px;
    height: 37px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  @media (max-width: 991.98px) {
    margin-top: 1rem;
  }

  @media (min-width: 992px) {
    height: 310px;
  }
`

const Title = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem;
  z-index: 2;
  width: 88%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 47.93%, #000000 100%);
  font-size: 18px;

  @media (max-width: 991.98px) {
    height: 100%;
  }
  @media (min-width: 992px) {
    height: 50%;
  }
`

const FPWrapper = styled.div`
  position: absolute;
  right: -5%;
  opacity: 0.2;
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Colleagues: React.FC<TeamProps> = ({ fields }) => {
  const {
    allWpTeamMember: { nodes: teamMembers },
  } = useStaticQuery<GatsbyTypes.fetchColleaguesQuery>(graphql`
    query fetchColleagues {
      allWpTeamMember {
        nodes {
          id
          teammemberdetail {
            info {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 300)
                  }
                }
              }
              name
              position
              linkedin
            }
          }
        }
      }
    }
  `)

  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  function getMultipleRandom(arr: any, num: number) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random())

    return shuffled.slice(0, num)
  }

  const randomTeamMemberIds = getMultipleRandom(teamMembers, 4).map(
    ({ id }) => id
  )

  return (
    <StyledTeam className="mb-0">
      <Container>
        <FPWrapper>
          <ViewPortAnimatedFingerprint />
        </FPWrapper>
        <div className="row align-items-center">
          <div className="col-lg-4 d-flex flex-column align-items-center">
            <Content content={fields.description} className="mb-5" />
            <ButtonWhiteArrow to="#" isCustom className="mx-auto">
              <button
                type="button"
                onClick={() => {
                  forceUpdate()
                }}
              >
                <span>{`Toon meer nieuwe collega's`}</span>
                <SVGWrapper>
                  <Refresh />
                </SVGWrapper>
              </button>
            </ButtonWhiteArrow>
          </div>
          <div className="col-lg-8">
            <div className="row">
              {teamMembers.map((edge) => {
                const hide = randomTeamMemberIds.indexOf(edge.id) === -1

                return (
                  <div
                    className={`col-6 col-md-3 ${hide ? 'd-none' : ''}`}
                    key={edge.id}
                  >
                    <div className="position-relative">
                      <StyledPlaatjie
                        image={edge.teammemberdetail?.info?.image}
                        alt={edge.teammemberdetail?.info?.name}
                      />
                      <Title className="position-absolute bottom-0 w-100">
                        {edge.teammemberdetail?.info?.name}
                      </Title>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Container>
    </StyledTeam>
  )
}

export default Colleagues
