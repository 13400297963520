import React from 'react'

function Refresh() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="#fff"
        d="M3.342 6.4A5.94 5.94 0 014.76 4.157a6.001 6.001 0 018.488 0l.641.645h-1.886a1.199 1.199 0 100 2.4H16.8c.664 0 1.2-.536 1.2-1.2v-4.8a1.199 1.199 0 10-2.4 0v1.92l-.657-.66A8.4 8.4 0 001.08 5.604a1.2 1.2 0 002.262.799V6.4zM.862 9.652a1.195 1.195 0 00-.847.975 1.096 1.096 0 00-.015.191v4.786c0 .664.536 1.2 1.2 1.2.664 0 1.2-.536 1.2-1.2v-1.916l.66.656c3.283 3.278 8.601 3.278 11.88 0a8.38 8.38 0 001.984-3.14 1.2 1.2 0 00-2.262-.798 5.94 5.94 0 01-1.418 2.243 6.001 6.001 0 01-8.488 0l-.004-.004-.641-.642H6c.664 0 1.2-.536 1.2-1.2 0-.664-.536-1.2-1.2-1.2H1.215c-.06 0-.12.004-.18.011a.883.883 0 00-.172.038z"
      />
    </svg>
  )
}

export default Refresh
